html,
body {
  background-color: white;
}

.ant-layout {
  background-color: white;
}

.ant-btn {
  border-radius: 4px;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
}

.ant-menu {
  background-color: transparent;
  border-inline-end: none !important;
  .ant-menu-item-selected {
    background-color: #e1e5f4 !important;
  }
  .ant-menu-item {
    color: black;
    &:after {
      left: 0;
      right: auto;
      border-color: #003bff;
    }
  }
}



.ant-layout-sider {
  background-color: #f3f3f3 !important;
}

.logo {
  padding: 24px;
  > img {
    width: 100%;
  }
}

