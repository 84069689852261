.overlay {
  display: flex;
  justify-content: center;
  height: 200px;
  width: 40px;
  border-radius: 40px;
  position: absolute;
  bottom: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  left: 0;
  z-index: -1;
  
  > div {
    padding: 16px 0 38px;
  }
  &.isVisible {
    transition: opacity .1s ease-in-out;
    max-height: 200px;
    opacity: 1;
  }
}

.volume {
  margin: 0 24px;
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  }
  position: relative;
}