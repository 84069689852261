.stems {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}

.uploadPreview {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #e6f4ff;
  border: 1px solid #91caff;
  border-radius: 12px;
  height: 120px;
  width: 100% !important;
  margin-bottom: 24px;
  cursor: pointer;
  > input {
    display: none;
    visibility: hidden;
    max-height: 0;
  }
 
}

.row2 {
  display: flex;
}

.trackWav {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.stem {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 8px;

  button {
    border-radius: 40px;
    height: 44px;
    width: 44px;
    margin-right: 12px;
  }

  > div:nth-child(2) {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    > div:nth-child(1) {
      font-weight: 500;
      margin-bottom: 4px;
    }
  }
}

.upload {
  > label {
    display: inline-block;
    background-color: #003bff;
    padding: 8px 14px;
    width: 100%;
    text-align: center;
    border-radius: 40px;
    color: white;
    cursor: pointer;
    input[type="file"] {
      display: none;
    }
    > span {
      margin-right: 4px;
    }
  }
}

.form {
  width: 100%;
  label {
    width: 180px;
  }
}
