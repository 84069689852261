.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.upload {
  > label {
    display: inline-block;
    background-color: #003bff;
    padding: 8px 14px;
    width: 100%;
    text-align: center;
    border-radius: 40px;
    color: white;
    cursor: pointer;
    input[type="file"] {
      display: none;
    }
    > span {
      margin-right: 4px;
    }
  }
}

.play {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid black;
  border-radius: 40px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 10px;
    height: 12px;
    border-style: double;
    border-width: 0px 0px 0px 10px;
    border-color: #202020;
  }

  &.isPlay {
    &:after {
      content: "";
      position: absolute;
      left: 12px;
      border-style: solid;
      border-color: #202020;
      border-width: 7px 0px 7px 9px;
      border-color: transparent transparent transparent #202020;
    }
  }
}
