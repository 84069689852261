
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  border: none;
  z-index: 10;
  position: relative;
  transition: opacity 0.1s ease;
  padding: 0;
  span {
    position: absolute;
    height: 24px;
  }
  &.loading {
    pointer-events: none !important;
    padding: 8px;
  }

  > svg {
    fill: black;
  }

}

.transparent {
  background-color: transparent;
}

.dots {
  &:after {
    content: "·";
    display: flex;
    width: 22px;
    height: 22px;
    animation: dots 1s steps(5, end) infinite;
    font-size: 30px;
  }
}

.large {
  width: 44px;
  height: 44px;
  flex: 0 0 44px;
  margin-right: 0px;
  > svg {
    transform: scale(1.2);
  }
}

.size_40 {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  margin-right: 0px;
  > svg {
    transform: scale(1.2);
  }
}

.light {
  background-color: white;
  > svg {
    fill: black;
  }
  &:hover {
    background-color: white;
  }

  .dots:after {
    animation: dots-light 1s steps(5, end) infinite !important;
  }
}

@keyframes dots-light {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}