.container {
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: fixed;
  bottom: 0;
  transform: translateY(100%);
  transition: max-height 0.2s ease-in-out, max-width 0.1s ease-in-out;
  background-color: white;
  width: 100%;
  max-height: 0;
  max-width: 100%;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  padding-right: 24px;
  > div:nth-child(2) {
    display: flex;
    align-items: center;
  }

  .player {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 80px;
    gap: 16px;
  }
}

.play {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  flex: 0 0 40px;
  width: 40px;
  border: 1px solid black;
  border-radius: 50%;
  &.small {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  }
}

.duration {
  text-align: center;
  width: 28px;
  flex: 0 0 28px;
  user-select: none;
}

.full {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.wave {
  height: 30px;
  width: 100%;
  flex-grow: 1;
  margin: 0 2px;
  position: relative;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  height: 100%;
  white-space: nowrap;
  flex-grow: 1;
  margin-right: 20px;
  flex: 0 0 250px;
  overflow: hidden;
  > a:nth-child(1) {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  > div:nth-child(2) {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    > a {
      font-weight: 700;
    }
  }
}

.controls {
  padding: 12px 0;
}

.showPlayer {
  opacity: 1;
  transform: translateY(0);
  max-height: 72px;
  overflow: visible;
}

.mobilePlayer {
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.2s ease;
  &.isVisible {
    max-height: 80px;
    overflow: visible;
  }
  &.isSeek {
    max-height: 100%;
    .mobilePlayerProgressTrack {
      height: 12px;
      transform: translateY(-4px);
      .thumb {
        transform: scale(1.5);
      }
    }
  }
}

.slider {
  display: flex;
  align-items: center;
  height: 32px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    inset: 18px 0 0 0;
  }
}

.hide {
  height: 0px;
  overflow: hidden;
}

.track {
  flex-grow: 1;
}
