.container {
  padding: 0 24px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 82px;
  padding: 0 24px;
  position: sticky;
  top: 0;
  z-index: 10;
  > h1,
  h2,
  h3 {
    margin-bottom: 0;
  }
}
